import React, { useMemo, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import CLink from "../components/cLink"
import tw, { css } from "twin.macro"
import { Formik } from "formik"
import filter from "lodash.filter"
import orderBy from "lodash.orderby"
import cloneDeep from "lodash.clonedeep"
import DescargaPdf from "./mini/descargaPdf"
import { contactFormSend } from "../services/contactForm"
import { GeneralContext } from "../contexts/generalContext"
import { Spring, animated as a } from "@react-spring/web"
import htmlFilters from "../helpers/htmlFilters"

const Footer = ({ isMounting, lang, langSettings }) => {
  const data = useStaticQuery(graphql`
    query menuFooterQuery {
      allSitePage {
        nodes {
          path
          context {
            level
            pagina_tipo
            texto_corto1
            title
            template
            ruta
            lang
            parent_page {
              ruta
            }
            external_link
          }
        }
      }
      processwire {
        config {
          es{
            catalogo_pdf {
              url
              title
            }
          }
          en{
            catalogo_pdf {
              url
              title
            }
          }
          fr{
            catalogo_pdf {
              url
              title
            }
          }
          pt{
            catalogo_pdf {
              url
              title
            }
          }
        }
      }
    }
  `)

  const generalContext = useContext(GeneralContext)
  const { t } = generalContext
  const pages = useMemo(
    () => {
      return filter(data.allSitePage.nodes, item => {
        return (
          item.context != null &&
          item.context.template !== "_config_blog_categoria" &&
          item.context.lang === lang
        )
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const pagesAreasPrivada = useMemo(
    () => {
      return filter(pages, page => {
        return (
          page.context.pagina_tipo === "AREA_PRIVADA" &&
          page.context.template === "page_privada"
        )
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const pagesLegal = useMemo(
    () => {
      return filter(pages, page => {
        return page.context.pagina_tipo === "PAGINA_LEGAL"
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const pagesPrincipal = useMemo(
    () => {
      const mainPages = filter(pages, page => {
        if (page.context.pagina_tipo === "PAGINA_PRINCIPAL") {
          const childrenPages = filter(pages, childPage => {
            return (
              childPage.context.template !== "page_ciudad" &&
              childPage.context.parent_page?.ruta === page.context.ruta
            )
          })

          if (childrenPages.length > 6) {
            childrenPages.splice(5)
            const nextPage = cloneDeep(page)
            nextPage.context.title = "Ver más..."
            childrenPages.push(nextPage)
          }
          page.context.childrenPages = childrenPages
        } else {
        }
        return page.context.pagina_tipo === "PAGINA_PRINCIPAL"
      })
      return orderBy(mainPages, "context.childrenPages", "desc")
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const formularioEnviado = error => {
    if (error) {
      generalContext.updateLoading({
        loadingState: "loaded",
        textoLoading1: "",
        textoLoaded1: t.forms.loadedWrong1,
        textoLoaded2: t.forms.loadedWrong2,
      })
    } else {
      generalContext.updateLoading({
        loadingState: "loaded",
        textoLoading1: "",
        textoLoaded1: t.forms.loadedOk1,
        textoLoaded2: t.forms.loadedOk2,
      })
    }
  }

  const calatologoPdf = data.processwire.config[lang].catalogo_pdf

  const hasContactCTA = langSettings.cta.buttonContactoActive


  return (
    <Spring
      to={{
        transform: `translateY(${isMounting ? "0rem" : "13rem"})`,
        opacity: isMounting ? 1 : 0,
      }}
    >
      {styles => (
        <a.div style={styles}>
          <div css={tw`relative font-obbody z-10`}>
            <div
              css={[
                tw`px-5 md:px-9`,
                hasContactCTA ? tw`bg-gris2` : tw`bg-gris1`,
              ]}
            >
              <div
                css={tw`md:grid md:grid-cols-12 max-w-screen-xl my-0 mx-auto px-0 font-obbody pt-8 pb-8`}
              >
                {!hasContactCTA ? (
                  <>
                    <div css={tw`md:col-span-6`}>
                      <Formik
                        initialValues={{
                          email: "",
                          pais: "",
                          movil: "",
                          cp: "",
                          autorizacion: "",
                        }}
                        validate={values => {
                          const errors = {}
                          if (!values.email) {
                            errors.email = true
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                              values.email
                            )
                          ) {
                            errors.email = true
                          }
                          if (!values.pais) {
                            errors.pais = true
                          }
                          if (!values.movil) {
                            errors.movil = true
                          }
                          if (!values.cp) {
                            errors.cp = true
                          }
                          if (!values.autorizacion) {
                            errors.autorizacion = true
                          }
                          console.log(errors)
                          return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          generalContext.updateLoading({
                            loadingState: "loading",
                            textoLoading1: "Enviando...",
                          })
                          contactFormSend(
                            values,
                            setSubmitting,
                            formularioEnviado,
                            "Formulario de pie de página",
                            lang
                          )
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                        }) => {
                          return (
                            <form onSubmit={handleSubmit}>
                              <div
                                css={tw`text-obsmall2 text-white pb-4 md:pb-5`}
                              >
                                {t.contact.text1}
                              </div>
                              <div css={tw`md:flex md:flex-wrap`}>
                                <div css={twInputTextContent}>
                                  <input
                                    css={[
                                      twInputText,
                                      errors.email &&
                                      touched.email &&
                                      twInputTextError,
                                    ]}
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    placeholder={t.forms.email}
                                  />
                                </div>
                                <div css={twInputTextContent}>
                                  <input
                                    css={[
                                      twInputText,
                                      errors.pais &&
                                      touched.pais &&
                                      twInputTextError,
                                    ]}
                                    type="text"
                                    name="pais"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.pais}
                                    placeholder={t.forms.country}
                                  />
                                </div>
                                <div css={twInputTextContent}>
                                  <input
                                    css={[
                                      twInputText,
                                      errors.movil &&
                                      touched.movil &&
                                      twInputTextError,
                                    ]}
                                    type="text"
                                    name="movil"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.movil}
                                    placeholder={t.forms.phone}
                                  />
                                </div>
                                <div css={twInputTextContent}>
                                  <input
                                    css={[
                                      twInputText,
                                      errors.cp &&
                                      touched.cp &&
                                      twInputTextError,
                                    ]}
                                    type="text"
                                    name="cp"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.cp}
                                    placeholder={t.forms.cp}
                                  />
                                </div>
                              </div>
                              <div css={tw`md:flex pt-3 md:pt-5`}>
                                <div css={tw`md:flex-1`}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="autorizacion"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.autorizacion}
                                    />
                                    <div
                                      css={[
                                        tw`pt-1 pb-3 md:pb-0 md:pr-4 text-obsmall2 text-white`,
                                        errors.autorizacion &&
                                        touched.autorizacion &&
                                        tw`text-red-500`,
                                      ]}
                                    >
                                      {t.forms.legalText}
                                    </div>
                                  </label>
                                </div>
                                <div css={tw`text-right`}>
                                  <button
                                    css={tw`bg-marron3 hover:bg-marron2 active:bg-marron1 w-20 h-10 text-white uppercase text-obsmall2 disabled:opacity-50 focus:outline-none`}
                                    type="submit"
                                    disabled={isSubmitting}
                                  >
                                    {t.forms.send}
                                  </button>
                                </div>
                              </div>
                            </form>
                          )
                        }}
                      </Formik>
                    </div>
                    <div css={tw`pt-12 md:pt-0 md:col-span-5 md:col-start-8`}>
                      <div css={tw`text-obsmall2 text-white pb-4 md:pb-5`}>
                        {t.footer.social}
                      </div>
                      <ul
                        css={tw`py-4 px-8 border border-gris3 flex md:flex-col lg:flex-row md:items-center justify-between `}
                      >
                        <li css={twRedesLi}>
                          <a
                            href="https://www.facebook.com/OBCocinas"
                            target="_blank"
                            rel="noreferrer"
                            css={twRedesA}
                            title="Facebook OB Cocinas"
                          >
                            Facebook
                          </a>
                        </li>
                        <li css={twRedesLi}>
                          <a
                            href="https://www.instagram.com/obcocinas/"
                            target="_blank"
                            rel="noreferrer"
                            css={twRedesA}
                            title="Instagram OB Cocinas"
                          >
                            Instagram
                          </a>
                        </li>
                        <li css={twRedesLi}>
                          <a
                            href="https://www.linkedin.com/company/ob-cocinas"
                            target="_blank"
                            rel="noreferrer"
                            css={twRedesA}
                            title="Linkedin OB Cocinas"
                          >
                            Linkedin
                          </a>
                        </li>
                        <li css={twRedesLi}>
                          <a
                            href="https://www.pinterest.es/obcocinas/"
                            target="_blank"
                            rel="noreferrer"
                            css={twRedesA}
                            title="Pinterest OB Cocinas"
                          >
                            Pinterest
                          </a>
                        </li>
                      </ul>
                      <div css={tw`text-right pt-9`}>
                        <DescargaPdf
                          dataType="Catalogo"
                          texto={calatologoPdf.title}
                          enlace={calatologoPdf.url}
                          color="#D1C6B5"
                          bgColor="#474747"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div css={tw`md:col-span-6`}>
                      <ul
                        css={tw`py-4 px-8 border border-gris3 flex md:flex-col lg:flex-row md:items-center justify-between `}
                      >
                        <li css={twRedesLi}>
                          <a
                            href="https://www.facebook.com/OBCocinas"
                            target="_blank"
                            rel="noreferrer"
                            css={twRedesA}
                            title="Facebook OB Cocinas"
                          >
                            Facebook
                          </a>
                        </li>
                        <li css={twRedesLi}>
                          <a
                            href="https://www.instagram.com/obcocinas/"
                            target="_blank"
                            rel="noreferrer"
                            css={twRedesA}
                            title="Instagram OB Cocinas"
                          >
                            Instagram
                          </a>
                        </li>
                        <li css={twRedesLi}>
                          <a
                            href="https://www.linkedin.com/company/ob-cocinas"
                            target="_blank"
                            rel="noreferrer"
                            css={twRedesA}
                            title="Linkedin OB Cocinas"
                          >
                            Linkedin
                          </a>
                        </li>
                        <li css={twRedesLi}>
                          <a
                            href="https://www.pinterest.es/obcocinas/"
                            target="_blank"
                            rel="noreferrer"
                            css={twRedesA}
                            title="Pinterest OB Cocinas"
                          >
                            Pinterest
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div
                      css={tw`hidden md:block md:pt-0 md:col-span-5 md:col-start-8`}
                    >
                      <div css={tw`text-right`}>
                        <DescargaPdf
                          dataType="Catalogo"
                          texto={calatologoPdf.title}
                          enlace={calatologoPdf.url}
                          color="#D1C6B5"
                          bgColor="#363636"
                          layout="horizontal"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div css={tw`px-5 md:px-9 pb-24 md:pb-0 bg-gris2`}>
              <div
                css={tw`md:flex md:justify-between max-w-screen-xl my-0 mx-auto px-0 font-obbody md:pt-8 pb-8`}
              >
                <div
                  css={tw`md:w-footercol1 text-obsmall text-white leading-5`}
                >
                  <strong>OB Cocinas</strong>
                  <br />
                  {htmlFilters(t.address.a1)}
                  <br />
                  {htmlFilters(t.address.a2)}
                  <br />
                  {htmlFilters(t.address.a3)}
                </div>
                <div
                  css={tw`pt-8 md:pt-0 md:w-footercol2 md:flex md:flex-col md:justify-between md:items-end`}
                >
                  <div>
                    {
                      // eslint-disable-next-line
                      pagesAreasPrivada.map((page, index) => {

                        if (page.context?.texto_corto1 && page.context.texto_corto1.startsWith("http")) {
                          return (
                            <a
                              key={index}
                              css={[tw`flex text-white text-obsmall font-bold uppercase hover:text-marron1 gap-2`, twAExterno]}

                              href={page.context.texto_corto1}>
                              {page.context.title}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={15}
                                height={17}
                                fill="none"
                                viewBox="0 0 15 17"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12.793 1.5h-2.2v-1h3.803v3.804h-1V2.312l-6.811 6.81-.707-.707L12.793 1.5ZM.5 3.038h7.57v1H1.5V13.5h9.462V6.93h1v7.57H.5V3.038Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </a>
                          )
                        }
                        return (
                          <CLink
                            key={index}
                            to={page.path}
                            css={tw`text-white text-obsmall font-bold uppercase hover:text-marron1`}
                          >
                            {page.context.title}
                          </CLink>
                        )
                      }
                      )
                    }
                  </div>
                  <div css={tw`pt-4 md:pt-0`}>
                    <ul>
                      {
                        // eslint-disable-next-line
                        pagesLegal.map((page, index) => {
                          return (
                            <li
                              key={index}
                              css={tw`inline-block mr-4 md:mr-0 md:ml-4`}
                            >
                              <CLink
                                css={tw`text-white  text-obsmall font-light hover:text-marron1`}
                                key={index}
                                to={page.path}
                              >
                                {page.context.title}
                              </CLink>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a.div>
      )}
    </Spring>
  )
}

const twInputTextContent = tw`w-full md:w-1/2 pb-2  md:pr-2`
const twInputText = tw`w-full md:flex-1  border-white border-2 bg-white placeholder-marron1 placeholder-opacity-70 text-gris1 text-base md:text-obsmall2 focus:border-marron1 outline-none focus:outline-none`
const twInputTextError = tw`border-red-800 dark:border-red-800 bg-red-200 dark:bg-red-200  text-red-800 placeholder-red-800`
const twRedesLi = tw`text-obsssmall sm:text-obsmall md:leading-6 lg:leading-3`
const twRedesA = tw`uppercase text-white hover:text-marron1 font-bold`
const twAExterno = css`
  svg {
    fill: white;
  }
  &:hover svg{
      fill: #847360;
  }
`
export default Footer
